<template>
  <div id="myProfile">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('menu.myProfile') }}</h2>
          <p></p>
        </div>
        <div class="main">
          <div class="title">
            <span>{{ $t('myProfile.header') }}</span>
          </div>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.accType') }}</span>
              <span>{{ accountType[clientProfileData.accountType] }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.name') }}</span>
              <span>{{ clientProfileData.name }}</span>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <span>{{ $t('common.field.emailAdd') }}</span>
              <span>{{ clientProfileData.emailAddress }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.phone') }}</span>
              <span>{{ clientProfileData.phone }}</span>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <span>{{ $t('common.field.dob') }}</span>
              <span>{{ clientProfileData.dateBirth }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.nat') }}</span>
              <span>{{ clientProfileData.nationality }}</span>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <span>{{ $t('common.field.country') }}</span>
              <span>{{ clientProfileData.country }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.street') }}</span>
              <span>{{ clientProfileData.streetAddress }}</span>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <span>{{ $t('common.field.suburbOrCity') }}</span>
              <span>{{ clientProfileData.suburb }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.provinceOrState') }}</span>
              <span>{{ clientProfileData.state }}</span>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <span>{{ $t('common.field.postcode') }}</span>
              <span>{{ clientProfileData.postcode }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.empStat') }}</span>
              <span>{{ clientProfileData.employmentStatus }}</span>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <span>{{ $t('common.field.occupation') }}</span>
              <span>{{ clientProfileData.occupation }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.save') }}</span>
              <span>{{ clientProfileData.savingsInvestment }}</span>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12">
              <span>{{ $t('common.field.annIncome') }}</span>
              <span>{{ clientProfileData.averageIncome }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="60">
            <el-col :xs="24" :sm="12" :md="12" :class="{ fr: this.bodyDirection === 'rtl' }">
              <span>{{ $t('common.field.source') }}</span>
              <span>{{ clientProfileData.sourceOfFunds }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiInfo } from '@/resource';

export default {
  data() {
    return {
      clientProfileData: {
        accountType: '',
        title: '',
        name: '',
        emailAddress: '',
        phone: '',
        dateBirth: '',
        occupation: '',
        averageIncome: '',
        country: '',
        streetAddress: '',
        suburb: '',
        state: '',
        postcode: '',
        cardNumber: ''
      },
      accountType: Object.freeze({
        1: 'Demo Account',
        2: 'Personal Account',
        3: 'Master Joint Account',
        4: 'Vice Joint Account',
        5: 'IB Individual Account',
        6: 'Internal Account',
        7: 'Experience Account',
        8: 'Leads Account',
        9: 'Repetitive Leads Account',
        10: 'Imported Leads Account',
        11: 'Irregular Leads Account',
        13: 'Company Account',
        14: 'SMSF Account',
        15: 'Vice Leads Account',
        16: 'IB Company Account',
        17: 'Migration Leads Account',
        18: 'Migration Demo Account',
        19: 'Conflict Account'
      }),
      bodyDirection: null
    };
  },
  mounted() {
    apiInfo().then(resp => (this.clientProfileData = resp.data.data));

    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
      console.log(this.bodyDirection);
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/myProfile.scss';
</style>
